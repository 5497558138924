<template>
    <div class="goods-info-content">
        <el-table :data="hotGoodsList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="1%">
            <el-table-column label="商品信息" width="450px">
                <template slot-scope="scope">
                    <div class="goods-info">
                        <img :src="hosturl + scope.row.good_images" alt="">
                        <span class="goods-name">{{scope.row.good_title}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="market_price" label="市场价" align="center"></el-table-column>
            <el-table-column prop="sales_price" label="销售价" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="150px">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false">
                        <router-link class="a-link" :to="{path: '/goods/detail', query: {g_id: scope.row.hot_goods_id, type: 1 }}" target="_blank">查看详情</router-link>
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="hotGoodsPages.currentPageNum"
                       :page-size="hotGoodsPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="hotGoodsPages.total"
                       @current-change="hotGoodsPagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { stuOpStuOpHotGoodsList } from '@/utils/apis.js'
    export default {
        name: "HotGoods",
        data() {
            return {
                //商品列表
                hotGoodsList: [],
                //分页
                hotGoodsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                hosturl: localStorage.getItem('hosturl'),
            }
        },
        mounted() {
            this.getHostGoodsList();
        },
        methods: {
            //获取热门商品列表
            getHostGoodsList() {
                let param = {
                    page: '1',
                    paging: this.hotGoodsPages.currentPageNum,
                    pageSize: this.hotGoodsPages.eachPageNum,
                    course_id: this.$route.query.course_id
                }
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                }
                stuOpStuOpHotGoodsList(param).then((res) => {
                    this.hotGoodsList = res.data.list;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //分页
            hotGoodsPagesCurrentChange(val) {
                this.hotGoodsPages.currentPageNum = val;
            },
        }
    }
</script>

<style scoped lang="scss">
    .goods-info-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .customTable {
            .goods-info {
                display: flex;
                align-items: center;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 10px;
                }
                .goods-name {
                    flex: 1;
                    width: 1%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    font-size: 16px;
                }
            }
            .a-link {
                font-size: 16px;
                color: #18B063;
            }
            ::v-deep {
                .el-table__empty-block {
                    background-image: url("../../../../assets/images/student/goods-null-data.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    .el-table__empty-text {
                        margin-top: 150px;
                    }
                }
            }
        }
    }
</style>